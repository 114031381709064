
import {defineComponent} from "vue";

export default defineComponent({
    name: 'StopwatchComponent',
    props: {
        timeInSeconds: {
            type: Number, 
            default: 0
        }
    },
    computed: {
        elapsedTime() : string {
            return new Date(this.timeInSeconds * 1000)
            .toISOString()
            .substr(11, 8)
        }
    }
})
