
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store';
import { NotificationType } from '@/interfaces/INotification';

export default defineComponent({
    name: "NotificationComponent",
    data () {
        return {
            context: {
                [NotificationType.SUCCESS]: 'is-success',
                [NotificationType.WARNING]: 'is-warning',
                [NotificationType.FAIL]: 'is-danger',
            }
        }
    },
    setup() {
        const store = useStore()
        return {
            notifications: computed(() => store.state.notifications)
        }
    }
});

