
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { EDIT_PROJECT, ADD_PROJECT } from "@/store/mutations-type";
import { NotificationType } from "@/interfaces/INotification";

import useNotifier from '@/hooks/notifier'

export default defineComponent({
    name: 'Form',
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            projectName: ''
        }
    },
    mounted() {
        if (this.id) {
            const project = this.store.state.projects.find(x => x.id == this.id);
            this.projectName = project?.name || '';
        }
    },
    methods: {
        salvar() {
            if (this.id) {
                this.store.commit(EDIT_PROJECT, {
                    id: this.id,
                    name: this.projectName
                })
            } else {
                this.store.commit(ADD_PROJECT, this.projectName);
            }
            this.projectName = '';    
            this.notificate(NotificationType.SUCCESS, "Success!", "Your new project is ready to use :)")
            this.$router.push('/projects');
        }
    },
    setup() {
        const store = useStore()
        const { notificate } = useNotifier()
        return {
            store,
            notificate
        }
    }
})
