import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormComponent = _resolveComponent("FormComponent")!
  const _component_TaskComponent = _resolveComponent("TaskComponent")!
  const _component_BoxComponent = _resolveComponent("BoxComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormComponent, { onToSaveTask: _ctx.saveTask }, null, 8, ["onToSaveTask"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, index) => {
        return (_openBlock(), _createBlock(_component_TaskComponent, {
          key: index,
          task: task,
          onTaskRemoved: ($event: any) => (_ctx.handleTaskRemoved(index))
        }, null, 8, ["task", "onTaskRemoved"]))
      }), 128)),
      (_ctx.isTasksEmpty)
        ? (_openBlock(), _createBlock(_component_BoxComponent, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(" You aren't so productive today :( ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}