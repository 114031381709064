import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "is-flex is-align-items-center is-justify-content-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StopwatchComponent = _resolveComponent("StopwatchComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StopwatchComponent, { timeInSeconds: _ctx.timeInSeconds }, null, 8, ["timeInSeconds"]),
    _createVNode(_component_ButtonComponent, {
      buttonName: 'play',
      buttonIcon: 'fas fa-play',
      isDisabled: _ctx.isStopwatchRunning,
      onButtonClicked: _ctx.start
    }, null, 8, ["isDisabled", "onButtonClicked"]),
    _createVNode(_component_ButtonComponent, {
      buttonName: 'stop',
      buttonIcon: 'fas fa-stop',
      isDisabled: !_ctx.isStopwatchRunning,
      onButtonClicked: _ctx.stop
    }, null, 8, ["isDisabled", "onButtonClicked"])
  ]))
}