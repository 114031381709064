
import { defineComponent } from 'vue';
import SidebarComponent from './components/SidebarComponent.vue';
import NotificationComponent from './components/NotificationComponent.vue';

export default defineComponent({
  name: 'App',
  components: {
    SidebarComponent,
    NotificationComponent
  },
  data() {
    return {
      darkModeOn: false
    }
  },
  methods: {
    changeTheme(darkModeOn: boolean) {
      this.darkModeOn = darkModeOn
    }
  }
});
