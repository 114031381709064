import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", _ctx.buttonClass]),
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonClicked && _ctx.buttonClicked(...args)))
  }, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.buttonIcon)
      }, null, 2)
    ]),
    (_ctx.buttonName.length > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.buttonName), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}