import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "column is-one-quarter" }
const _hoisted_2 = { class: "column is-three-quarter content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_NotificationComponent = _resolveComponent("NotificationComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["columns is-gapless is-multiline", { 'dark-mode': _ctx.darkModeOn }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SidebarComponent, { onToThemeChanged: _ctx.changeTheme }, null, 8, ["onToThemeChanged"])
    ]),
    _createVNode(_component_NotificationComponent),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ], 2))
}