
import { useStore, store } from "@/store";
import { REMOVE_PROJECT } from "@/store/mutations-type";
import { defineComponent, computed } from "vue";

export default defineComponent({
    name: 'List',
    setup() {
        return {
            projects: computed(() => store.state.projects),
            store
        }
    },
    methods: {
        remove(id: string) {
            this.store.commit(REMOVE_PROJECT, id)
        }
    }
})
