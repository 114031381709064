import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel is-centered" }
const _hoisted_2 = { class: "columns is-centered" }
const _hoisted_3 = { class: "panel-tabs" }
const _hoisted_4 = { class: "columns is-centered" }
const _hoisted_5 = { class: "panel-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PomodoroComponent = _resolveComponent("PomodoroComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("a", {
          class: _normalizeClass({ 'is-active': _ctx.tabAtiva === 'pomodoro' }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchPomodoro('pomodoro')))
        }, "Pomodoro", 2),
        _createElementVNode("a", {
          class: _normalizeClass({ 'is-active': _ctx.tabAtiva === 'short' }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchPomodoro('short')))
        }, "Short Break", 2),
        _createElementVNode("a", {
          class: _normalizeClass({ 'is-active': _ctx.tabAtiva === 'long' }),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchPomodoro('long')))
        }, "Long Break", 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.tabAtiva === 'pomodoro')
          ? (_openBlock(), _createBlock(_component_PomodoroComponent, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.tabAtiva === 'short')
          ? (_openBlock(), _createBlock(_component_PomodoroComponent, {
              key: 1,
              pomodoroTimer: 5
            }))
          : _createCommentVNode("", true),
        (_ctx.tabAtiva === 'long')
          ? (_openBlock(), _createBlock(_component_PomodoroComponent, {
              key: 2,
              pomodoroTimer: 15
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}