
  import { defineComponent } from 'vue';

  export default defineComponent ({
    name: 'SwitchComponent',
    emits: ['switched'],
    props: {
        switchName: {
            type: String,
            default: ''
        }
    },
    data() {
      return {
        isChecked: false
      }
    },
    methods: {
      toggleSwitch() {
        this.$emit('switched', this.isChecked)
      },
    },
  })
  