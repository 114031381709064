
import { defineComponent, PropType } from 'vue';
import StopwatchComponent from './StopwatchComponent.vue';
import BoxComponent from './BoxComponent.vue'
// import ButtonComponent from './ButtonComponent.vue';

import ITask from '../interfaces/ITask'

export default defineComponent({
    name: 'TaskComponent',
    emits: ['taskRemoved'],
    components: {
        BoxComponent,
        StopwatchComponent,
        // ButtonComponent
    },
    props: {
        task: {
            type: Object as PropType<ITask>,
            required: true
        }
    },
    methods: {
        taskRemoved() {
            this.$emit('taskRemoved', true)
        }
    }
})
