
import { defineComponent } from 'vue';
import PomodoroComponent from '@/components/PomodoroComponent.vue';

export default defineComponent({
    name: 'Pomodoro',
    components: {
        PomodoroComponent
    },
    methods: {
        switchPomodoro(tab: string) {
            this.tabAtiva = tab;
        }
    },
    data() {
        return {
            tabAtiva: 'pomodoro'
        }
    }
})

