
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { key } from '@/store'

import TimerComponent from './TimerComponent.vue';

export default defineComponent({
    name: 'FormComponent',
    emits: ['toSaveTask'],
    components: {
        TimerComponent
    },
    data() {
        return {
            description: '',
            projectId: ''
        }
    },
    methods: {
        finishTask(elapsedTime: number): void {
            this.$emit('toSaveTask', {
                timeInSeconds: elapsedTime,
                description: this.description,
                project: this.projects.find(x => x.id == this.projectId)
            })
            this.description = ''
        }
    },
    setup() {
        const store = useStore(key)
        return {
            projects: computed(() => store.state.projects)
        }
    }
})
