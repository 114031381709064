import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a184570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  for: "switchBulma",
  style: {"color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: "switchBulma",
      class: "switch is-rounded",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSwitch && _ctx.toggleSwitch(...args)))
    }, null, 544), [
      [_vModelCheckbox, _ctx.isChecked]
    ]),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.switchName), 1)
  ]))
}