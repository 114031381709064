
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ButtonComponent',
    emits: ['buttonClicked'],
    props: {
        buttonName: {
            type: String,
            default: ''
        },
        buttonIcon: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: ''
        }
    },
    methods: {
        buttonClicked(){
            this.$emit('buttonClicked', true)
        }
    }
})
