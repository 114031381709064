
import { defineComponent } from 'vue'
import  SwitchComponent  from './SwitchComponent.vue'

export default defineComponent({
    name: 'SidebarComponent',
    components: {
        SwitchComponent
    },
    emits: ['toThemeChanged'],
    data() {
        return {
            darkModeOn: false
        }
    },
    computed: {
        buttonText() {
            if (this.darkModeOn) {
                return 'Disable Dark Mode'
            }

            return 'Enable Dark Mode'
        }
    },
    methods: {
        changeTheme() {
            this.darkModeOn = !this.darkModeOn
            this.$emit('toThemeChanged', this.darkModeOn)
        }
    }
})
